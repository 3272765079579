/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from "react";
import { colors } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ChatIcon from "@material-ui/icons/ChatOutlined";
import CodeIcon from "@material-ui/icons/Code";

import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined";
import MailIcon from "@material-ui/icons/MailOutlined";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import DragHandleRoundedIcon from "@material-ui/icons/DragHandleRounded";
import DesignIcon from "@material-ui/icons/CropOriginal";
import AdvertiseIcon from "@material-ui/icons/AttachMoney";
import PublishIcon from "@material-ui/icons/Publish";
import VotersIcon from "@material-ui/icons/PeopleAlt";
import AnalyticsIcon from "@material-ui/icons/Equalizer";
import GettingStartedIcon from "@material-ui/icons/LiveHelp";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import Cookies from "js-cookie";
import RemoveIcon from "@material-ui/icons/Remove";

import { Label } from "components";

const ballot_id = Cookies.get("ballot_id");

const design_path = "/settings/";
const ads_settings_path = "/ads-settings/";
const publish_path = "/publish/";
const import_path = "/imports";
const import_finallist_path = "/imports-finallist";
const export_finallist_path = "/exports/";
const super_settings_path = "/super-settings/";
const all_customers_path = "/all-customers";
const clone_ballot_path = "/clone-ballot";
const super_users_path = "/super-users";
const system_settings_path = "/system-settings";
const remove_duplicates_path = "/remove-duplicates/";
const organization_settings_path = "/organization-settings";
const map_website_to_ballot_path = "/map-website-to-ballot-path";
const map_website_to_guide_path = "/map-website-to-guide";
const winners_portal_admin_path = "/winners-portal/guides";
const winners_portal_import_path = "/winners-portal/imports";
const winners_portal_export_path = "/winners-portal/exports";

const getAppendPath = () => {
  if (Cookies.get("ballot_type") == "photo") {
    return "/photo-ballot";
  } else {
    return "";
  }
};
console.log("getappendpath", getAppendPath());
export default [
  {
    title: "Choose Admin",
    pages: [
      {
        title: "Ballots",
        href: "/ballots",
        icon: HomeIcon,
      },
      {
        title: "Winners Portal",
        href: winners_portal_admin_path,
        icon: HomeIcon,
      },
      {
        title: "Audience",
        href: "/audience",
        icon: VotersIcon,
      },
      {
        title: "Emails",
        href: "/emails",
        icon: MailIcon,
      },
      {
        title: "Org Settings",
        href: organization_settings_path,
        icon: SettingsIcon,
        not_allowed_roles: ["accountExecutive"],
      },
    ],
  },
  {
    title: "SUPER SETTINGS",
    pages: [
      {
        title: "All",
        href: all_customers_path,
        icon: VotersIcon,
      },
      {
        title: "Super Users",
        href: super_users_path,
        icon: VotersIcon,
      },
      {
        title: "Clone Ballot",
        href: clone_ballot_path,
        icon: ImportExportIcon,
      },
      {
        title: "System Settings",
        href: system_settings_path,
        icon: SettingsIcon,
      },
      // {
      //   title: "Map Website to Ballot",
      //   href: map_website_to_ballot_path,
      //   icon: VotersIcon,
      // },
      // {
      //   title: "Map Website to Guide",
      //   href: map_website_to_guide_path,
      //   icon: VotersIcon,
      // },
    ],
  },
  // {
  //   pages: [
  //     {
  //       title: "Organization Settings",
  //       href: organization_settings_path,
  //       icon: SettingsIcon,
  //       not_allowed_roles: ["accountExecutive"],
  //     },
  //   ],
  // },
  {
    title: "Ballots",
    pages: [
      {
        title: "All",
        href: "/ballots",
        icon: HomeIcon,
      },
    ],
  },
  {
    title: "BALLOT SETUP",
    pages: [
      {
        title: "Ballot Overview",
        href: getAppendPath() + "/ballotoverview",
        icon: BarChartIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "Ballot Builder",
        href: getAppendPath() + "/categories",
        icon: ReceiptIcon,
        not_allowed_roles: ["accountExecutive"],
      },

      {
        title: "Design",
        href: getAppendPath() + design_path,
        icon: DesignIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "Advertising",
        href: getAppendPath() + ads_settings_path,
        icon: AdvertiseIcon,
      },
      {
        title: "Publish",
        href: getAppendPath() + publish_path,
        icon: PublishIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "Import",
        href: getAppendPath() + import_path,
        icon: ImportExportIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      // {
      // title: "Import Final List",
      //  href: import_finallist_path,
      //  icon: ImportExportIcon,
      // },
      // {
      //   title: "Remove Duplicates",
      //   href: remove_duplicates_path,
      //   icon: RemoveIcon,
      // },
      {
        title: "Export",
        href: getAppendPath() + export_finallist_path,
        icon: ImportExportIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "Super Settings",
        href: getAppendPath() + super_settings_path,
        icon: SettingsIcon,
        not_allowed_roles: ["accountExecutive"],
      },
    ],
  },
  {
    title: "PORTAL SETUP",
    pages: [
      {
        title: "Settings",
        href: "/winners-portal/general-settings",
        icon: SettingsIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "Portal Builder",
        href: "/winners-portal/categories",
        icon: ReceiptIcon,
        not_allowed_roles: ["accountExecutive"],
      },

      {
        title: "Advertising",
        href: "/winners-portal/ads-settings",
        icon: AdvertiseIcon,
      },
      {
        title: "Publish",
        href: "/winners-portal/publish-settings",
        icon: PublishIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "Import",
        href: winners_portal_import_path,
        icon: ImportExportIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "Export",
        href: winners_portal_export_path,
        icon: ImportExportIcon,
        not_allowed_roles: ["accountExecutive"],
      },

      {
        title: "Analytics",
        href: "/winners-portal/general-settings",
        icon: ImportExportIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "Super Settings",
        href: "/winners-portal/super-settings",
        icon: SettingsIcon,
        not_allowed_roles: ["accountExecutive"],
      },
    ],
  },
  {
    title: "MANAGE",
    pages: [
      {
        title: "Submissions",
        href: getAppendPath() + "/votes/overview",
        icon: VotersIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "People By Votes",
        href: getAppendPath() + "/people/analytics",
        icon: AnalyticsIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "People By Voters",
        href: getAppendPath() + "/people/analyticsByVoters",
        icon: AnalyticsIcon,
        not_allowed_roles: ["accountExecutive"],
      },
      {
        title: "All Voters",
        href: getAppendPath() + "/all-voters",
        icon: AnalyticsIcon,
        not_allowed_roles: ["accountExecutive"],
      },
    ],
  },
];
