import React, { Fragment, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Drawer, Divider, Paper, Avatar, Typography } from "@material-ui/core";
import { Hidden } from "@material-ui/core";

import useRouter from "utils/useRouter";
import { Navigation } from "components";
import navigationConfig from "./navigationConfig";
import Cookies from "js-cookie";
import checkProperBallotId from "utils/checkProperBallotId";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: "#263237",
    height: "300%",
  },
  contentBasic: {
    padding: theme.spacing(2),
    backgroundColor: "#3E3E3E",
    height: "300%",
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
    color: "white",
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
    color: "black !important",
  },
}));

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  const session = useSelector((state) => state.session);
  const [ballotName, setBallotName] = useState("");
  const [guideName, setGuideName] = useState("");
  const [ballotType, setBallotType] = useState();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    setGuideName(Cookies.get("guide_name"));
    let isDifferentBallotId = checkProperBallotId();
    if (isDifferentBallotId) {
      setBallotName(sessionStorage.getItem("forthistabballotname"));
    } else {
      setBallotName(Cookies.get("ballot_name"));
    }
    Cookies.get("ballot_type") == "photo"
      ? setBallotType("photo")
      : setBallotType("ballot");
  }, [router.location.pathname]);

  const navbarContent = (
    <div
      className={
        window.location.pathname !== "/ballots" &&
        window.location.pathname !== "/ballots/" &&
        window.location.pathname !== "/all-customers" &&
        window.location.pathname.indexOf("organization-settings") < 0
          ? classes.content
          : classes.contentBasic
      }
    >
      {Cookies.get("ballot_name") != 0 &&
        window.location.pathname.indexOf("winners-portal") < 0 &&
        window.location.pathname !== "/ballots" &&
        window.location.pathname !== "/ballots/" &&
        window.location.pathname !== "/all-customers" &&
        window.location.pathname.indexOf("organization-settings") < 0 && (
          <div className={classes.profile}>
            <Typography className={classes.name} variant="h4">
              {ballotName}
            </Typography>
          </div>
        )}
      {window.location.pathname.indexOf("winners-portal") >= 0 &&
        window.location.pathname.indexOf("winners-portal/guides") < 0 &&
        window.location.pathname.indexOf("organization-settings") < 0 && (
          <div className={classes.profile}>
            <Typography className={classes.name} variant="h4">
              {guideName}
            </Typography>
          </div>
        )}
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {ballotType &&
          navigationConfig.map((list) => {
            if (
              list.title == "Choose Admin" &&
              list.title != "SUPER SETTINGS"
            ) {
              if (window.location.pathname !== "/all-customers") {
                return (
                  <Navigation
                    component="div"
                    key={list.title}
                    pages={list.pages}
                    title={list.title}
                  />
                );
              }
            } else if (list.title == "BALLOT SETUP" || list.title == "MANAGE") {
              if (
                Cookies.get("ballot_name") &&
                window.location.pathname !== "/ballots" &&
                window.location.pathname !== "/all-customers" &&
                window.location.pathname.indexOf("all-customers") < 0 &&
                window.location.pathname.indexOf("winners-portal") < 0
              ) {
                return (
                  <Navigation
                    component="div"
                    key={list.title}
                    pages={list.pages}
                    title={list.title}
                  />
                );
              }
            } else if (list.title == "PORTAL SETUP") {
              if (
                Cookies.get("guide_name") &&
                window.location.pathname.indexOf("winners-portal") >= 0 &&
                window.location.pathname.indexOf("winners-portal/guides") < 0
              ) {
                return (
                  <Navigation
                    component="div"
                    key={list.title}
                    pages={list.pages}
                    title={list.title}
                  />
                );
              }
            } else if (list.title == "SUPER SETTINGS") {
              if (
                Cookies.get("super_admin") === "true" &&
                window.location.pathname !== "/choose-admin/"
              ) {
                return (
                  <Navigation
                    component="div"
                    key={list.title}
                    pages={list.pages}
                    title={list.title}
                  />
                );
              }
            } else if (list.pages[0].title == "Organization Settings") {
              if (
                Cookies.get("show_org_nav") === "true" &&
                window.location.pathname !== "/all-customers" &&
                window.location.pathname !== "/choose-admin/"
              ) {
                return (
                  <Navigation
                    component="div"
                    key={list.title}
                    pages={list.pages}
                    title={list.title}
                  />
                );
              }
            } else if (
              list.title == "Ballots" &&
              list.title != "PORTAL SETUP"
            ) {
              if (
                Cookies.get("show_org_nav") === "true" &&
                window.location.pathname !== "/choose-admin/" &&
                window.location.pathname.indexOf("all-customers") < 0 &&
                window.location.pathname.indexOf("winners-portal") < 0
              ) {
                return (
                  <Navigation
                    component="div"
                    key={list.title}
                    pages={list.pages}
                    title={list.title}
                  />
                );
              }
            } else {
              return (
                <Navigation
                  component="div"
                  key={list.title}
                  pages={list.pages}
                  title={list.title}
                />
              );
            }
          })}
      </nav>
    </div>
  );

  return (
    <Fragment>
      {window.location.pathname.indexOf("/create-ad") < 0 &&
        window.location.pathname.indexOf("/edit-profile-link") < 0 &&
        window.location.pathname.indexOf("/multi-create-ad") < 0 &&
        window.location.pathname.indexOf("/create-group-ad") < 0 &&
        window.location.pathname.indexOf("/multi-create-group-ad") < 0 &&
        window.location.pathname.indexOf(
          "/create-winners-upgraded-listing-ad"
        ) < 0 &&
        window.location.pathname.indexOf("/multi-create-winners-ad") < 0 &&
        window.location.pathname.indexOf("/multi-create-winners-group-ad") <
          0 && (
          <>
            {" "}
            <Hidden lgUp>
              <Drawer
                anchor="left"
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
              >
                <div {...rest} className={clsx(classes.root, className)}>
                  {navbarContent}
                </div>
              </Drawer>
            </Hidden>
            <Hidden mdDown>
              <Paper
                {...rest}
                className={clsx(classes.root, className)}
                elevation={1}
                square
              >
                {navbarContent}
              </Paper>
            </Hidden>{" "}
          </>
        )}
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
